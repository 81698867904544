/**
 * @name BRITE_BACKEND_TYPE
 * @constant
 * @type {'BRITE'}
 */
const BRITE_BACKEND_TYPE = 'BRITE'

/**
 * @name PIQ_BACKEND_TYPE
 * @constant
 * @type {'PIQ'}
 */
const PIQ_BACKEND_TYPE = 'PIQ'

/**
 * @name PIQKYC_BACKEND_TYPE
 * @constant
 * @type {'PIQKYC'}
 */
const PIQKYC_BACKEND_TYPE = 'PIQKYC'

const PRAXIS_BACKEND_TYPE = 'PRAXIS'

/**
 * @name PROJS_BACKEND_TYPE
 * @constant
 * @type {'PROJS'}
 */
const PROJS_BACKEND_TYPE = 'PROJS'

/**
 * @name PWCLICK_BACKEND_TYPE
 * @constant
 * @type {'PWCLICK'}
 */
const PWCLICK_BACKEND_TYPE = 'PWCLICK'

/**
 * @name NODAPAY_BACKEND_TYPE
 * @constant
 * @type {'NODAPAY'}
 */
const NODAPAY_BACKEND_TYPE = 'NODAPAY'

/**
 * @name TRANSFERWORLD_BACKEND_TYPE
 * @constant
 * @type {'TRANSFERWORLD'}
 */
const TRANSFERWORLD_BACKEND_TYPE = 'TRANSFERWORLD'

/**
 * Available backend types
 * @readonly
 * @enum {('BRITE'|'PIQ'|'PIQKYC'|'PROJS'|'NODAPAY'|'PWCLICK'|'TRANSFERWORLD')}
 */
export const BackendType = {
  BRITE: BRITE_BACKEND_TYPE,
  PIQ: PIQ_BACKEND_TYPE,
  PIQKYC: PIQKYC_BACKEND_TYPE,
  PRAXIS: PRAXIS_BACKEND_TYPE,
  PROJS: PROJS_BACKEND_TYPE,
  PWCLICK: PWCLICK_BACKEND_TYPE,
  NODAPAY: NODAPAY_BACKEND_TYPE,
  TRANSFERWORLD: TRANSFERWORLD_BACKEND_TYPE,
}

/**
 * Use BankID/Swish app installed on current player’s device
 * @name LOCAL_PROJS_ORDER_METHOD
 * @constant
 * @type {'local'}
 */
const LOCAL_PROJS_ORDER_METHOD = 'local'

/**
 * Use BankID app on other device by entering personal number
 *
 * @name REMOTE_PERSONAL_NUMBER_PROJS_ORDER_METHOD
 * @constant
 * @type {'remote_personal_number'}
 */
const REMOTE_PERSONAL_NUMBER_PROJS_ORDER_METHOD = 'remote_personal_number'

/**
 * Use BankID app on other device by scanning QR code
 *
 * @name REMOTE_QR_CODE_PROJS_ORDER_METHOD
 * @constant
 * @type {'remote_qr_code'}
 */
const REMOTE_QR_CODE_PROJS_ORDER_METHOD = 'remote_qr_code'

/**
 * Available Projs order methods
 * @readonly
 * @enum {('local'|'remote_personal_number'|'remote_qr_code')}
 */
export const ProjsOrderMethod = {
  local: LOCAL_PROJS_ORDER_METHOD,
  remotePersonalNumber: REMOTE_PERSONAL_NUMBER_PROJS_ORDER_METHOD,
  remoteQrCode: REMOTE_QR_CODE_PROJS_ORDER_METHOD,
}

/**
 * @constant
 * @type {'CANCELLED'}
 */
const CANCELLED_TRANSACTION_STATE = 'CANCELLED'

/**
 * @constant
 * @type {'FAILED'}
 */
const FAILED_TRANSACTION_STATE = 'FAILED'

/**
 * @constant
 * @type {'PENDING'}
 */
const PENDING_TRANSACTION_STATE = 'PENDING'

/**
 * @constant
 * @type {'PROCESSING'}
 */
const PROCESSING_TRANSACTION_STATE = 'PROCESSING'

/**
 * @constant
 * @type {'SUCCESSFUL'}
 */
const SUCCESSFUL_TRANSACTION_STATE = 'SUCCESSFUL'

/**
 * @constant
 * @type {'WAITING_APPROVAL'}
 */
const WAITING_APPROVAL_TRANSACTION_STATE = 'WAITING_APPROVAL'

/**
 * @constant
 * @type {'WAITING_INPUT'}
 */
const WAITING_INPUT_TRANSACTION_STATE = 'WAITING_INPUT'

/**
 * Available transaction states
 * @readonly
 * @enum {('CANCELLED'|'FAILED'|'PENDING'|'PROCESSING'|'SUCCESSFUL'|'WAITING_APPROVAL'|'WAITING_INPUT'|'WAITING_RESPONSE')}
 */
export const TransactionState = {
  CANCELLED: CANCELLED_TRANSACTION_STATE,
  FAILED: FAILED_TRANSACTION_STATE,
  PENDING: PENDING_TRANSACTION_STATE,
  PROCESSING: PROCESSING_TRANSACTION_STATE,
  SUCCESSFUL: SUCCESSFUL_TRANSACTION_STATE,
  WAITING_APPROVAL: WAITING_APPROVAL_TRANSACTION_STATE,
  WAITING_INPUT: WAITING_INPUT_TRANSACTION_STATE,
}

/**
 * @constant
 * @type {'auth'}
 */
const AUTH_TRANSACTION_TYPE = 'auth'

/**
 * @constant
 * @type {'deposit'}
 */
const DEPOSIT_TRANSACTION_TYPE = 'deposit'

/**
 * @constant
 * @type {'withdrawal'}
 */
const WITHDRAWAL_TRANSACTION_TYPE = 'withdrawal'

/**
 * Available transaction types
 * @readonly
 * @enum {('auth'|'deposit'|'withdrawal')}
 */
export const TransactionType = {
  AUTH: AUTH_TRANSACTION_TYPE,
  DEPOSIT: DEPOSIT_TRANSACTION_TYPE,
  WITHDRAWAL: WITHDRAWAL_TRANSACTION_TYPE,
}

export const ServiceType = {
  APCO: 'apco',
  AIRTEL_WALLET: 'airtell_wallet',
  APPLEPAY: 'applepay',
  GOOGLEPAY: 'googlepay',
  BDBANKS: 'bdbanks',
  BITPACE: 'bitpace',
  BL: 'bl',
  BOLETO: 'boleto',
  BOLETO_PIX: 'boleto_pix',
  BRITE: 'brite',
  BT: 'bt',
  BTB: 'btb',
  BTB_AUTO: 'btb_auto',
  BTJPN: 'btjpn',
  CREDIT_CARD: 'credit_card',
  CRYPTOPAY: 'cryptopay',
  DIRECTA24: 'directa24',
  DIRECT_PAYMENT: 'direct_payment',
  D24INDIA: 'd24india',
  GO: 'go',
  IB: 'ib',
  IX: 'ix',
  JC: 'jc',
  BTVOUCHER: 'btvoucher',
  BTVOUCHER_ATM: 'btvoucher_atm',
  PPBTVOUCHER: 'ppbtvoucher',
  EBP: 'ebp',
  FLYKK: 'flykk',
  FINSUPPORT: 'finsupport',
  MACROPAY: 'macropay',
  MASTERCARD: 'mastercard',
  MANDATO: 'mandato',
  NB: 'nb',
  NETBANKING: 'netbanking',
  NET_BANKING: 'net_banking',
  NODAPAY: 'nodapay',
  ONLINEUEBERWEISEN: 'onlineueberweisen',
  ONLINE_BANKING: 'online_banking',
  ONLINE_DEBIT: 'online_debit',
  OP: 'op',
  IDEAL: 'ideal',
  INOVAPAY: 'inovapay',
  INPAY: 'inpay',
  INPAYJP: 'inpayjp',
  INTERAC: 'interac',
  INTERKASSA: 'interkassa',
  ISIGNTHIS: 'isignthis',
  JPAY: 'jpay',
  JIO_WALLET: 'jio_wallet',
  MOBIKWIK_WALLET: 'mobikwik_wallet',
  NZD: 'nzd',
  ONRAMP: 'onramp',
  PAGAVA: 'pagava',
  PARAMOUNT: 'paramount',
  PAYRETAILERS: 'payretailers',
  PAYTM_WALLET: 'paytm_wallet',
  PH: 'ph',
  PHONEPE_WALLET: 'phonepe_wallet',
  PIX: 'pix',
  PIX_ONLINE: 'pix_online',
  PNE: 'pne',
  POINTINOUT_EWALLET: 'pointinout',
  REDEEM_VOUCHER: 'redeem_voucher',
  RUPAY: 'rupay',
  QAICASH: 'qaicash',
  LOCAL_EWALLET: 'qpoints',
  LOCAL_BANK_TRANSFER: 'local_bank_transfer',
  QUICKBIT: 'quickbit',
  SOFORT: 'sofort',
  STICPAY: 'sticpay',
  SUMOPAY: 'sumopay',
  TIGERPAY: 'tigerpay',
  TM: 'tm',
  UI: 'ui',
  UPI: 'upi',
  UPI_H5: 'upi_h5',
  VIPWITHDRAWAL_JP: 'vipwithdrawal_jp',
  VISA: 'visa',
  VOLT: 'volt',
  WALLET: 'wallet',
  ZOTAPAY: 'zotapay',
  ZOTACARD: 'zotacard',
  PAYTM: 'paytm',
  IMPS: 'imps',
  GPAY: 'gpay',
  PHONEPE: 'phonepe',
  PWCLICK: 'pwclick',
  PRAXIS: 'praxis',
  UNIONPAY: 'unionpay',
  KLARNA: 'klarna',
  SPARKASSE: 'sparkasse',
  VOLKSBANKEN: 'volksbanken',
  DEUTSCHEBANK: 'deutschebank',
  POSTBANK: 'postbank',
  COMMERZBANK: 'commerzbank',
  DEUTSCHEKREDITBANK: 'deutschekreditbank',
  GATE2WAY: 'gate2way',
  GATE2WAYSOFORT: 'gate2waysofort',
  GATE2WAYWEBPAYZ: 'gate2waywebpayz',
  GATE2WAYSKRILL: 'gate2wayskrill',
  GATE2WAYNETELLER: 'gate2wayneteller',
  GATE2WAYGIROPAY: 'gate2waygiropay',
  GATE2WAYPAYSAFECARD: 'gate2waypaysafecard',
  GATE2WAYPAYSAFECASH: 'gate2waypaysafecash',
  TRANSFERWORLD: 'transfer_world',
}
